/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FEATURE_FLAG = "ALTERNATE_OLDEST_FIRST_VIEW" | "AVATARS" | "COMMENT_SEEN" | "DEFAULT_QA_STORY_MODE" | "DISABLE_LIVE_UPDATES" | "DISABLE_WARN_USER_OF_TOXIC_COMMENT" | "DISCUSSIONS" | "EMAIL_META" | "ENABLE_QA" | "ENABLE_RATINGS_AND_REVIEWS" | "EXTERNAL_MEDIA" | "FLATTEN_REPLIES" | "FOR_REVIEW" | "NEW_COMMENT_COUNT" | "READ_MORE_NEW_TAB" | "REDUCED_SECURITY_MODE" | "RTE_SARCASM" | "SECTIONS" | "SITE_MODERATOR" | "VIEWER_COUNT" | "Z_KEY" | "%future added value";
export type MODERATION_MODE = "POST" | "PRE" | "%future added value";
export type ModerateNavigationContainer_settings = {
    readonly moderation: MODERATION_MODE | null;
    readonly featureFlags: ReadonlyArray<FEATURE_FLAG>;
    readonly " $refType": "ModerateNavigationContainer_settings";
};
export type ModerateNavigationContainer_settings$data = ModerateNavigationContainer_settings;
export type ModerateNavigationContainer_settings$key = {
    readonly " $data"?: ModerateNavigationContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateNavigationContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateNavigationContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "moderation",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureFlags",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'd6d88786276d3a4a616a41a7171926c9';
export default node;
