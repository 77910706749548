/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FEATURE_FLAG = "ALTERNATE_OLDEST_FIRST_VIEW" | "AVATARS" | "COMMENT_SEEN" | "DEFAULT_QA_STORY_MODE" | "DISABLE_LIVE_UPDATES" | "DISABLE_WARN_USER_OF_TOXIC_COMMENT" | "DISCUSSIONS" | "EMAIL_META" | "ENABLE_QA" | "ENABLE_RATINGS_AND_REVIEWS" | "EXTERNAL_MEDIA" | "FLATTEN_REPLIES" | "FOR_REVIEW" | "NEW_COMMENT_COUNT" | "READ_MORE_NEW_TAB" | "REDUCED_SECURITY_MODE" | "RTE_SARCASM" | "SECTIONS" | "SITE_MODERATOR" | "VIEWER_COUNT" | "Z_KEY" | "%future added value";
export type UserStatusChangeContainer_settings = {
    readonly organization: {
        readonly name: string;
    };
    readonly multisite: boolean;
    readonly featureFlags: ReadonlyArray<FEATURE_FLAG>;
    readonly " $refType": "UserStatusChangeContainer_settings";
};
export type UserStatusChangeContainer_settings$data = UserStatusChangeContainer_settings;
export type UserStatusChangeContainer_settings$key = {
    readonly " $data"?: UserStatusChangeContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"UserStatusChangeContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserStatusChangeContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multisite",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureFlags",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'df7073b75f462761b80e2c3ea99fde4d';
export default node;
